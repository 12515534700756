import React from 'react';
import PropTypes from 'prop-types';
import { ScaleLoader } from 'react-spinners';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    override: {
        display: 'block',
        margin: '0 auto',
        borderColor: 'red'
    }
});

class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }
  render() {
      const { classes } = this.props;
      return (
          <div className='sweet-loading'>
              <ScaleLoader
                  className={classes.override}
                  sizeUnit={"px"}
                  size={150}
                  color={'#391599'}
                  loading={this.state.loading}
              />
          </div> 
    )
  }
}

Loading.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loading);
