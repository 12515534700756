import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { updateAudioPosition } from '../actions/index'

const styles = theme => ({ });

class WordSpan extends React.Component {
  constructor(props) {
    super(props);

    let error = this.props.word.confidence.toFixed(3) * 100 < 85 ? true : false
    this.state = { 
      error, 
      current: false 
    }
    this.wordstart = Number(this.props.word.start.seconds + '.' + this.props.word.start.nanos)
    this.wordend = Number(this.props.word.end.seconds + '.' + this.props.word.end.nanos)
  }
  componentDidUpdate(prevProps) {
    if (this.props.wavesurfer.current_time !== prevProps.wavesurfer.current_time) {
      if (this.wordstart <= this.props.wavesurfer.current_time && this.props.wavesurfer.current_time < this.wordend) {
        this.setState(() => ({ current: true }));
      } else {
        this.setState(() => ({ current: false }));
      }
    }
  }
  onClick = () => {
    this.props.updateAudioPosition(this.wordstart)
    this.setState(() => ({ error: false }));
  }

  render() {
    let { word } = this.props;
    let confidence = word.confidence.toFixed(3) * 100;
    let start = `${word.start.seconds}.${word.start.nanos / 100000000}`
    let end = `${word.end.seconds}.${word.end.nanos / 100000000}`

    const title = `Confidence: ${confidence}%\nStart: ${start} seconds\nEnd: ${end} seconds`

    const normal = { display: 'inline' }
    const error = { display: 'inline', color: '#ed0000' }
    const normal_current = { 
      display: 'inline', 
      fontWeight: 'bold'
    }
    const error_current = { display: 'inline', fontWeight: 'bold', color: '#ed0000' }

    let style = normal;
    if (this.state.error && !this.state.current) {
      style = error
    } else if (this.state.error && this.state.current) {
      style = error_current
    } else if (!this.state.error && this.state.current) {
      style = normal_current
    }

    return (
      <Typography 
        onClick={this.onClick}
        title={title} 
        variant='body1' 
        style={style} 
        {...this.props.attributes}>
        {this.props.children}
      </Typography>
    )
  }
}

WordSpan.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateAudioPosition }, dispatch)
} 

const mapStateToProps = ({ wavesurfer }) => ({ wavesurfer });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WordSpan));
