import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Loading from './Loading'

const styles = theme => ({
    outerDiv: {
        textAlign: 'center',
        position: 'fixed',
        zIndex: '9999',
        display: 'table',
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
        width: '100%',
        height: '100%',
        //position: 'absolute',
    },
    middleDiv: {
        display: 'table-cell',
        verticalAlign: 'middle',
    },
    innerDiv: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

 
class Overlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = { }
  }
  render() {
      return (
          <div style={{
              textAlign:'center', 
              //position:'fixed', 
              zIndex:'9999', 
              display:'table', 
              backgroundColor:'rgba(255, 255, 255, 0.75)', 
              width:'100%', 
              height:'100%', 
              position:'absolute'}}>
              <div style={{
                  display:'table-cell',
                  verticalAlign:'middle' }}>
                  <div style={{
                      marginLeft:'auto',
                      marginRight:'auto',
                  }}>
                  <Loading />
                  </div> 
              </div> 
          </div> 
      )
  }
}

Overlay.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Overlay);
