import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import NewSpeaker from './NewSpeaker'
import SpeakerName from './SpeakerName'

const styles = theme => ({})

class SpeakerSelector extends React.Component {
  render() {
    console.log(this.props.editedtranscript)
    return (
      <div style={{ 
        backgroundColor: 'white', 
        width: '160px',
        border:'#9f00d433 1.5px solid', 
        borderTop: 'none',
        cursor: 'pointer',
        zIndex: '1',
        padding: '0.5px 1px 0.5px 0px',
        textAlign: 'right',
        marginLeft: '-2px',
        position:'absolute' }}>
        { this.props.editedtranscript.map(({ speaker }, i) => {
          return (
            <SpeakerName 
              key={i}
              speakerKey={this.props.speakerKey}
              handleSpeakerChange={this.props.handleSpeakerChange} 
              speaker={speaker} />
          )
        })}
        <NewSpeaker />
      </div>
    )
  }
}

SpeakerSelector.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ }, dispatch);
}
const mapStateToProps = ({ 
  editor,
  editedtranscript,
  speakers
}) => ({ 
  editor,
  editedtranscript,
  speakers
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SpeakerSelector));
