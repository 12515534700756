import types from '../actions/types';

const INITIAL_STATE = null;

export default function OriginalTranscript(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.TRANSCRIPTION_SUCCESS:
      if (!action.originaltranscript) { 
        return action.mockedtranscription 
      }
      return action.originaltranscript;

    default:
    //do nothing
  }
  return state;
}
