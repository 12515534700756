import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import SpeakerSelector from './SpeakerSelector'
import { updateSpeakers } from '../actions/index'

const ARROW = require('../img/icon-speaker-arrow.svg');

const styles = theme => ({})

class SpeakerDiv extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hovering: false,
      clicked: false
    }
    this.onMouseEnterSpeaker = this.onMouseEnterSpeaker.bind(this)
    this.onMouseLeaveSpeaker = this.onMouseLeaveSpeaker.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleSpeakerChange = this.handleSpeakerChange.bind(this)
  }
  onMouseEnterSpeaker() {
    this.setState((prevState) => ({ hovering: true }));
  }
  onMouseLeaveSpeaker() {
    this.setState((prevState) => ({ 
      hovering: false,
      clicked: false
    }));
  }
  handleClick() {
    this.setState((prevState) => ({ clicked: true }));
  }
  handleSpeakerChange(speaker) {
    this.props.updateSpeakers(this.props.editedtranscript[this.props.speakerKey].speaker, speaker)
  }
  render() {
    return (
      <div style={this.state.hovering ? 
          { 
            border:'#9f00d433 1.5px solid', 
            borderBottom: 'none',
            cursor: 'pointer',
            display:'inline-block', 
            width:'161px', 
            padding: '0.5px 0px 0.5px 0px',
            marginRight:'25px'} 
          : 
          { display:'inline-block', 
            width:'160px', 
            padding: '2px 1.5px 0.5px 1.5px',
            marginRight:'25px'}}
            onMouseEnter={this.onMouseEnterSpeaker}
            onMouseLeave={this.onMouseLeaveSpeaker}
            onClick={this.handleClick}
            contentEditable={false} {...this.props.attributes}>
              <Typography 
                contentEditable={false}
                variant='body1'
                style={{
                  display:'inline-block',
                  fontWeight:'bold',
                  minWidth: '125px',
                  textAlign: 'right'
                }}>
                {this.props.editedtranscript[this.props.speakerKey].speaker}
              </Typography>
              <img 
                alt='arrow' 
                src={ARROW} 
                style={(this.state.clicked || this.state.hovering) ? { 
                  height: '12px',
                  marginTop: '7px',
                  marginLeft: '8px',
                  position:'fixed',
                  transform: 'rotate(90deg)'
                } : {
                  height: '12px',
                  marginTop: '7px',
                  marginLeft: '8px',
                  position:'fixed',
                }} />
            {this.state.hovering && 
                <SpeakerSelector 
                  style={{marginLeft:'-5px'}}
                  speakerKey={this.props.speakerKey}
                  addNewSpeaker={this.props.addNewSpeaker}
                  handleSpeakerChange={this.handleSpeakerChange} />} 
              </div>
    )
  }
}

SpeakerDiv.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateSpeakers }, dispatch);
}
const mapStateToProps = ({ editedtranscript }) => ({ editedtranscript });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SpeakerDiv));
