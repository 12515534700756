
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    minHeight: '80px',
  },
  rootBar: {
    margin: 'auto 0px'
  },
  appLogo: {
    height: '42px'
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

function QuillAppbar(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
			<AppBar position="absolute" className={classes.appBar}>
				<Toolbar className={classes.rootBar}>
					<IconButton
						color="inherit"
						aria-label="Open menu"
						className={classes.menuButton}
					>
            <MenuIcon />
					</IconButton>
          <img 
            className={classes.appLogo} 
            src={require('../img/Quill-logo-white.svg')} 
            alt="Quill" />
          <div className={classes.grow} />
          <Button color="inherit">Logout</Button>
				</Toolbar>
			</AppBar>
    </div>
  );
}

QuillAppbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(QuillAppbar);

