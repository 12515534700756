import { createMuiTheme } from '@material-ui/core/styles';

// https://material-ui-next.com/customization/themes/
// https://material-ui-next.com/style/color/
export default createMuiTheme({
  palette: {
    primary: {
      //main: '#60c3ab',
      main: '#391599',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#9f00d4',
      //main: '#3a9b81',
    },
  },
  overrides: {
    MuiButton: {
      raisedPrimary: {
        color: 'white',
      },
    },
  },
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true
  },
});
