import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';

import { playAudio, pauseAudio, skipToPosition } from '../actions/index'

const BOLD = require('../img/icon-bold.svg');
const ITALIC = require('../img/icon-italic.svg');
const UNDERLINE = require('../img/icon-underlined.svg');
const UNDO = require('../img/icon-undo.svg');
const REDO = require('../img/icon-redo.svg');
const PLAY = require('../img/play-right-triangle-arrow-sketch.png');
const PAUSE = require('../img/pause.png');
const RW = require('../img/rewind-two-arrows-sketched-button.png');
const FF = require('../img/fast-forward-sketched-arrows.png');

const styles = theme => ({
  toolbar: {
    padding:'5px 0px 5px 20px', 
    //border:'#60c3ab33 1.5px solid', 
    border:'#9f00d433 1.5px solid', 
    borderRadius: '0px 0px 5px 5px',
    backgroundColor: '#9f00d424',
    //backgroundColor: '#60c3ab24',
    height:'20px'
  },
  toggleSwitch: {
    marginTop: '-14px',
    transform: 'scale(0.8)',
    position: 'fixed'
  },
  editorControls: {
    display:'inline',
    float:'left',
    height:'20px',
    paddingLeft: '25px'
  },
  audiofileName: {
    display:'inline-flex',
    marginRight:'10px',
    fontSize:'0.8rem',
    fontWeight:'bold'
  },
  originalTxt: {
    display:'inline-flex',
    marginLeft:'50px',
    fontSize:'0.8rem',
    fontWeight:'bold'
  },
  playbackControls: {
    textAlign: 'right',
    paddingRight: '50px',
    display:'inline',
    float:'right',
    height:'20px'
  },
  toolbarBtn: {
    margin: '0px 1px',
    padding: '2px 2px',
    minWidth: '20px',
    minHeight: '20px',
    borderRadius: '2px'
  },
  toolbarEditorIcon: {
    height: '18px',
    width: '18px',
  },
  toolbarPlaybackIcon: {
    height: '15px',
    width: '18px',
    margin: '0px 1px'
  }
});

class OutputToolbar extends React.Component {

  handleSkip = (skipLength) => {
    let newPosition = (this.props.wavesurfer.current_time + skipLength)
    if (newPosition > 0) {
      this.props.skipToPosition(newPosition)
    } else if (newPosition < 0) {
      this.props.skipToPosition(0)
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.toolbar}>
        <div className={classes.editorControls}>
          <Button 
            className={classes.toolbarBtn} 
            onClick={() => { }}>
            <img 
              className={classes.toolbarEditorIcon} 
              alt='bold' 
              src={BOLD} />
          </Button>
          <Button 
            className={classes.toolbarBtn} 
            onClick={() => { }}>
            <img 
              className={classes.toolbarEditorIcon} 
              alt='italic' 
              src={ITALIC} />
          </Button>
          <Button 
            className={classes.toolbarBtn} 
            onClick={() => { }}>
            <img 
              className={classes.toolbarEditorIcon} 
              alt='underline' 
              src={UNDERLINE} />
          </Button>
          <Button 
            className={classes.toolbarBtn} 
            onClick={() => { }}>
            <img 
              className={classes.toolbarEditorIcon} 
              alt='undo' 
              src={UNDO} />
          </Button>
          <Button 
            className={classes.toolbarBtn} 
            onClick={() => { }}>
            <img 
              className={classes.toolbarEditorIcon} 
              alt='redo' 
              src={REDO} />
          </Button>
          <Switch 
            className={classes.toggleSwitch} 
            value='checked' 
            color='default' />
          <Typography 
            className={classes.originalTxt} 
            variant="subtitle1">Orig.</Typography>
        </div>
        <div 
          className={classes.playbackControls}>
          <Typography className={classes.audiofileName} 
            variant="subtitle1">{this.props.audiofile.name}</Typography>
          <Button 
            className={classes.toolbarBtn} 
            onClick={() => { this.props.playAudio() }}>
            <img 
              className={classes.toolbarPlaybackIcon} 
              src={PLAY} 
              alt="" />
          </Button>
          <Button 
            className={classes.toolbarBtn} 
            onClick={() => { this.props.pauseAudio() }}>
            <img 
              className={classes.toolbarPlaybackIcon} 
              src={PAUSE} alt="" />
          </Button>
          <Button 
            className={classes.toolbarBtn} 
            onClick={() => { this.handleSkip(-1) }}>
            <img 
              className={classes.toolbarPlaybackIcon} 
              src={RW} 
              alt="" />
          </Button>
          <Button 
            className={classes.toolbarBtn} 
            onClick={() => { this.handleSkip(1) }}>
            <img 
              className={classes.toolbarPlaybackIcon} 
              src={FF} 
              alt="" />
          </Button>
        </div>
      </div>
    )
  }
}

//function BoldMark(props) {
//return <strong>{props.children}</strong>
//}

//function ItalicMark(props) {
//return <em>{props.children}</em>
//}

//function UnderlineMark(props) {
//return <u>{props.children}</u>
//}

OutputToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ 
    playAudio, 
    pauseAudio,
    skipToPosition
  }, dispatch);
}

const mapStateToProps = ({ audio, wavesurfer}) => ({ audio, wavesurfer });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OutputToolbar));
