// FileUploadGraphic.jsx
import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  fuDiv: {
    textAlign: 'center',
    margin: '0px auto 10% auto',
    width: '50%',
    height: '30vh',
    minHeight: '200px',
    maxHeight: '500px',
    maxWidth: '500px',
    minWidth: '350px',
    padding: '50px',
    border: '2px lightgray dashed',
    borderRadius: '15px',
    color: 'gray',
    display: 'table'
  },
  fuDragDiv: {
    textAlign: 'center',
    margin: '0px auto 10% auto',
    width: '50%',
    height: '30vh',
    minHeight: '200px',
    maxHeight: '500px',
    maxWidth: '500px',
    minWidth: '350px',
    padding: '50px',
    //border: '3px #60c3ab6b dashed',
    border: '3px #9f00d4 dashed',
    backgroundColor: '#9f00d424',
    //backgroundColor: '#60c3ab14',
    borderRadius: '15px',
    color: 'gray',
    display: 'table'
  },
  fuContentDiv: {
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  microphoneImg: {
    marginTop: '-30px'    
  },
  txtDragDrop: { 
    fontSize: '18px',
    color: '#999999'
  },
  uploadInput: { 
    position: "absolute",
    width: "135px", 
    height: "39px", 
    opacity: "0", 
    zIndex: '2',
  },
  uploadBtn: {
    textTransform: 'none',
    color: 'gray',
    backgroundColor: 'white',
    zIndex:'1',
  },
  uploadBtnHover: {
    textTransform: 'none',
    color: 'gray',
    borderColor: '#9f00d4',
    backgroundColor: '#9f00d424',
    //borderColor: '#60c3ab',
    //backgroundColor: '#60c3ab24',
    zIndex:'1',
  },
  hr: {
    margin: '30px',
    borderColor: '#fffafa'
  }
});

class FileUploadGraphic extends React.Component {

  constructor() {
    super();
    this.state = { btnHovered: false };
    this.handleHover = this.handleHover.bind(this);
  }
  handleHover() {
    this.setState((prevState) => ({
      btnHovered: !prevState.btnHovered
    }));
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={this.props.dragging ? classes.fuDragDiv : classes.fuDiv}>
        <div className={classes.fuContentDiv}>
          <img className={classes.microphoneImg} src={require('../img/dropzone-icon.svg')} alt="" />
          <Typography variant="body1" className={classes.txtDragDrop}>Drag and drop audio to transcribe</Typography>
          <hr className={classes.hr} />
          <div>
            <input 
              className={classes.uploadInput} 
              onMouseEnter={this.handleHover}
              onMouseLeave={this.handleHover}
              type="file" 
              accept="audio/*" 
              onChange={(e) => { this.props.handleFileSelection(e.target.files[0]) }} />
            <Button 
              className={this.state.btnHovered ? classes.uploadBtnHover : classes.uploadBtn}
              variant="outlined">Choose a File..</Button>
          </div>
        </div>
      </div>
    )
  }
};

FileUploadGraphic.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FileUploadGraphic);
