import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  textViewDiv: {
    height: '60vh',
    padding: '25px 75px',
    overflow: 'scroll',
    textAlign: 'left'
  },
});

class ViewText2 extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.textViewDiv}>
        <table style={{width:'100%', margin:'auto'}}>
          <tbody>
            {this.props.speakers.map((speaker, i) => {
              return (
                <tr key={i}>
                  <td style={{float:'right'}}>
                    <Typography>{speaker}:</Typography>
                  </td>
                  <td style={{paddingLeft:'20px'}}>
                    <Typography>{this.props.sentences[i]}</Typography>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
};

ViewText2.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ 
  editedtranscript, 
  speakers,
  sentences 
}) => ({ 
  editedtranscript, 
  speakers,
  sentences 
});

export default connect(mapStateToProps)(withStyles(styles)(ViewText2));
