import types from '../actions/types';

const INITIAL_STATE = {};

export default function EditedTranscriptReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.TRANSCRIPTION_SUCCESS:
      if (!action.originaltranscript) { return action.mockedtranscription.sentences }
      return action.originaltranscript.sentences

    case types.UPDATE_SINGLE_SPEAKER:
      let newState = [...state]
      newState[action.payload.key].speaker = action.payload.updated
      return newState

    case types.UPDATE_SENTENCE_SPEAKERS:
      let updatedState = [...state]
      action.payload.indices.forEach((i) => {
        updatedState[i].speaker = action.payload.updated
      })
      return updatedState

    default:
    //do nothing
  }
  return state;
}
