import types from '../actions/types';

const INITIAL_STATE = [];

export default function SentencesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_SENTENCES:
      return action.payload

    default:
    //do nothing
  }
  return state;
}
