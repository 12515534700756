// Stepper.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const styles = theme => ({
  root: {
    width: '100%',
    padding: '35%',
  },
  stepperDiv: {
    width: '30%',
    height: '90px',
    position: 'fixed',
    bottom: '5px'
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  iconContainer: {
    transform: 'scale(1.3)'
  }
});

function getSteps() {
  //return ['Upload', 'View & Edit', 'Export'];
  return [0,1,2];
}

class ProgressStepper extends React.Component {
  constructor(props) {
    super(props);

    this.handleLabelClick = this.handleLabelClick.bind(this);
  }

  handleLabelClick(label) {
    if (this.props.audiofile) {
      switch (label) {
        case 0:
          this.props.renderUploadComponent();
          break;
        case 1:
          this.props.renderOutputComponent();
          break;
        case 2:
          this.props.renderExportComponent();
          break;
        default:
          break;
      }
    }
  }

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { pageindex } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.stepperDiv}>
          <Stepper activeStep={pageindex} alternativeLabel>
            {steps.map(label => {
              return (
                <Step key={label} onClick={() => {
                  this.handleLabelClick(label);
                }} >
                <StepLabel 
                  className={classes.iconContainer}
                ></StepLabel>
              </Step>
              );
            })}
          </Stepper>
        </div>
      </div>
    );
  }
}

ProgressStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(ProgressStepper);
