import types from '../actions/types';

export default function PageIndexReducer(state = 0, action) {
  switch (action.type) {
    case types.UPDATE_PAGE_INDEX:
      return action.payload;

    case types.GO_TO_PREVIOUS_PAGE:
      return state - 1;

    case types.GO_TO_NEXT_PAGE:
      return state + 1;

    case types.TRANSCRIPTION_SUCCESS:
      const index = 1;
      return index;

    default:
    //do nothing
  }
  return state;
}
