import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import FileUpload from '../components/FileUpload';
import Stepper from '../components/Stepper';
import Output from './Output';
import Export from '../components/Export';
import Overlay from './Overlay';

import { uploadAndTranscribeAudio } from '../actions/index';
//import { uploadAndTranscribeAudio } from '../actions/dev';

const styles = theme => ({
  mainDiv: {
    width: '100%',
    marginTop: '80px'
  },
  btnBack: {
    zIndex: '1000',
    position: 'fixed',
    left: '20px',
    top: '80px',
  },
  currentPageDiv: {
    display: 'table',
    width: '100%',
    height: '100vh',
    border: 'none',
    overflow: 'scroll',
  },
});

class Main extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      pageindex: 0,
      audiofile: null,
    }
    this.handleFileSelection = this.handleFileSelection.bind(this)
    this.renderUploadComponent = this.renderUploadComponent.bind(this)
    this.renderOutputComponent = this.renderOutputComponent.bind(this)
    this.renderExportComponent = this.renderExportComponent.bind(this)
  }
  renderUploadComponent() {
    this.setState({ pageindex:0 })
  }
  renderOutputComponent() {
    this.setState({ pageindex:1 })
  }
  renderExportComponent() {
    this.setState({ pageindex:2 })
  }
  handleFileSelection(audio) {
    this.props.uploadAndTranscribeAudio(audio);
    this.setState({ audiofile:audio })
    this.renderOutputComponent();
  }
  render() {

    const { classes } = this.props;
    var currentPage;

    const uploadPage = <FileUpload handleFileSelection={this.handleFileSelection} />;

    if (!this.state.audiofile) {
      currentPage = uploadPage;
    } else {
      switch (this.state.pageindex) {
        case 0:
          currentPage = uploadPage;
          break;
        case 1:
          currentPage = <Output 
            loading={this.props.loading}
            originaltranscript={this.props.originaltranscript}
            audiofile={this.state.audiofile} />;
          break;
        case 2:
          currentPage = <Export />;
          break;
        default:
          currentPage = uploadPage;
      }
    }

    return (
      <div className={classes.mainDiv}>
        {this.props.loading && <Overlay />}
        <div className={classes.currentPageDiv}>
          {currentPage}
        </div>
        <Stepper 
          renderUploadComponent={this.renderUploadComponent}
          renderOutputComponent={this.renderOutputComponent}
          renderExportComponent={this.renderExportComponent}
          audiofile={this.state.audiofile}
          pageindex={this.state.pageindex} />
      </div>
    )
  }
};

Main.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ uploadAndTranscribeAudio }, dispatch);
}

const mapStateToProps = ({ 
  loading, 
  audio, 
  originaltranscript
}) => ({ 
  loading, 
  audio, 
  originaltranscript
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Main));
