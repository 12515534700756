// Export.jsx
import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

//import ExportOptions from './ExportOptions'
import ExportOptions2 from './ExportOptions2'
//import ViewText from './ViewText'
import ViewText2 from './ViewText2'

const styles = theme => ({
    outputDiv: {
        padding: '80px',
        width: '100%',
        height: '100vh',
        overflow: 'scroll',
    }
});

class Export extends React.Component {

    render() {
        return (
            <div>
                <ExportOptions2 />
                <ViewText2 />
            </div>
        )
    }
};

Export.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Export);
