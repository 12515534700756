import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import WaveSurfer from 'wavesurfer.js';

import { updateAudioPosition } from '../actions/index'

const styles = theme => ({ });

class AudioOutput extends React.Component {

  componentDidMount() {
    this.$el = ReactDOM.findDOMNode(this)
    this.$waveform = this.$el.querySelector('.wave')
    this.wavesurfer = WaveSurfer.create({
      container: this.$waveform,
      //waveColor: '#60c3ab',
      //progressColor: '#3a9b81',
      waveColor: '#9f00d4',
      progressColor: '#391599',
      barWidth: 5,
      barHeight: 0.75,
      cursorColor: '#391599',
      cursorWidth: 2.5,
      //height: 128
    });

    //this.wavesurfer.on('ready', () => {
      //this.wavesurfer.play();
    //});
    this.wavesurfer.on('audioprocess', () => {
      this.props.updateAudioPosition(this.wavesurfer.getCurrentTime())
    })
    this.wavesurfer.on('interaction', () => {
      // TODO: re-renders component infinitely
      if (!this.wavesurfer.isPlaying() && !this.props.wavesurfer.playing) {
        if (this.props.wavesurfer !== this.wavesurfer.getCurrentTime()) {
          //this.props.updateAudioPosition(this.wavesurfer.getCurrentTime())
        }
      }
    })

    let audio = new Audio();
    audio.src = URL.createObjectURL(this.props.audiofile);
    this.wavesurfer.load(audio.src);
  }
  componentDidUpdate(prevProps) {
    if (this.props.wavesurfer.current_time !== prevProps.wavesurfer.current_time) {
      if (this.props.wavesurfer.current_time !== this.wavesurfer.getCurrentTime()) {
        let position = this.props.wavesurfer.current_time / this.wavesurfer.getDuration();
        this.wavesurfer.seekAndCenter(position)
      }
    }
  }
  componentWillUnmount() {
    this.wavesurfer.stop();
  }

  render() {
    if (this.props.wavesurfer.playing) {
      this.wavesurfer.play(this.props.wavesurfer.current_time);
    }
    if (this.props.wavesurfer.paused) {
      this.wavesurfer.pause();
    }
    return (
      <div 
        className='waveform' 
        style={{ 
          marginBottom: '2px',
          marginTop: '2px',
          backgroundColor: '#9f00d424', 
          border:'#9f00d433 1.5px solid',
          borderRadius: '5px 5px 0px 0px',
          padding:'10px 25px' }}>
        <div className='wave' style={{ height:'80%', overflowX:'hidden' }}></div>
      </div>
    )
  }
};

AudioOutput.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateAudioPosition }, dispatch);
}

const mapStateToProps = ({ audio, wavesurfer }) => ({ audio, wavesurfer });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AudioOutput));
