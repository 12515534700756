import { combineReducers } from 'redux';
import AudioReducer from './reducer_audio';
import WaveSurferReducer from './reducer_wavesurfer';
import LoadingReducer from './reducer_loading';
import OriginalTranscriptReducer from './reducer_originaltranscript';
import EditedTranscriptReducer from './reducer_editedtranscript';
import PageIndexReducer from './reducer_pageindex';
import SentencesReducer from './reducer_sentences';
import SpeakersReducer from './reducer_speakers';

const rootReducer = combineReducers({
  loading: LoadingReducer,
  pageindex: PageIndexReducer,
  audio: AudioReducer,
  wavesurfer: WaveSurferReducer,
  originaltranscript: OriginalTranscriptReducer,
  editedtranscript: EditedTranscriptReducer,
  sentences: SentencesReducer,
  speakers: SpeakersReducer
});

export default rootReducer;
