import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Editor } from 'slate-react'
import { Value } from 'slate'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import SpeakerDiv from './SpeakerDiv'
import WordSpan from './WordSpan'

import { 
  updateSpeakersArray,
  updateSentencesArray,
} from '../actions/index';

const styles = theme => ({
  editor: {
    height: '100%',
    margin: '25px',
  },
  container: {
    maxWidth: '100%',
    overflowX: 'hidden'
  }
});

function MarkHotkey(options) {
  //Grab our options from the ones passed in.
  const { type, key  } = options
  return {
    onKeyDown(event, change, next) {
      //if (event.keyCode === 8) {
        //console.log('backspace')
      //}
      if (event.keyCode === 13) {
        let speaker = 'speaker 0'
        let key = 0
        change.splitBlock()
          .moveToStartOfNode(change.value.focusBlock)
          .insertBlock({
            type: 'node_div',
            nodes: [
              {
                object: 'inline',
                type: 'speaker_div',
                data: {speaker, key}
              },
            ]
          })
          .mergeNodeByKey(change.value.nextBlock.key)
        //console.log(change)

        return true
      }
      // If it doesn't match our `key`, let other plugins handle it.
      if (!event.ctrlKey || event.key !== key) return next()
      // Prevent the default characters from being inserted.
      event.preventDefault()
      // Toggle the mark `type`.
      change.toggleMark(type)
      return true
    },
	}
}

const boldPlugin = MarkHotkey({
  type: 'bold',
  key: 'b',
})
const italicPlugin = MarkHotkey({
  type: 'italic',
  key: 'i',
})
const underlinePlugin = MarkHotkey({
  type: 'underline',
  key: 'u',
})

const plugins = [boldPlugin, italicPlugin, underlinePlugin]

function NodeDiv(props) {
  return (
    <div style={{marginTop:'5px'}} {...props.attributes}>{props.children}</div>
  )
}
function SentenceDiv(props) {
  return (
    <div style={{display:'inline', backgroundColor:'#9f00d424', padding:'3px'}}
    {...props.attributes}>{props.children}</div>
  )
}

class QuillEditor extends React.Component {

  constructor(props) {
    super(props)

    let speakers = this.props.originaltranscript.sentences.map(({speaker}) => {
      // remove parantheses from speaker name
      let speakerString = speaker.replace(/[()]/g, '')
      return speakerString 
    })
    this.props.updateSpeakersArray(speakers);

    let key = -1;
    const mapTranscriptionToDocument = ({ sentences }) => {
      function createBlock(phrase) {
        key++
        const speaker = phrase.speaker;
        const words = phrase.words;

        const sentence = words.map((word, i) => {
          // remove whitespace after last word in sentence
          var whitespace = (i === words.length - 1) ? true : false
          return ({
            object: 'inline',
            type: 'word_span',
            data: word,
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: `${word.word}`
                  },
                  {
                    text: whitespace ? '' : ' '
                  }
                ]
              }
            ]
          })
        })

        return ({
          object: 'block',
          type: 'node_div',
          nodes: [
            {
              object: 'inline',
              type: 'speaker_div',
              data: {speaker, key}
            },
            {
              object: 'inline',
              type: 'sentence_div',
              nodes: sentence
            }
          ]
        })
      }
      return sentences.map(createBlock)
    }

    const blocks = mapTranscriptionToDocument(this.props.originaltranscript);
    const documentObj = { document: { nodes: blocks } }
    const initialValue = Value.fromJSON(documentObj)

    this.state = { value: initialValue }
    //console.log(this.props.speakers)
  }
  onDocumentChange = ({value}) => {
    this.setState({ value }, () => {
      console.log(value)

      console.log(this.props.editedtranscript)
      let sentences = value._map._root.entries[2][1]._map._root.entries[2][1]._tail.array.map((block, i) => {
        return block.__cache_no_args.getText.trim()
      })
      this.props.updateSentencesArray(sentences)
      if (this.props.speakers.length !== value.document.nodes._tail.array.length) {
        let nodesArrayKeys = value.document.nodes._tail.array.map(({key}, i) => {
          return value.anchorBlock.key === key ? true : false
        })
        let newBlockIndex = nodesArrayKeys.indexOf(!false)
        let speakers = this.props.speakers
        speakers.splice(newBlockIndex, 0, this.props.speakers[0])
        this.props.updateSpeakersArray(speakers)
        speakers.length = sentences.length
      }
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.container}>
          <Editor 
            className={classes.editor}
            value={this.state.value}
            plugins={plugins}
            renderNode={this.renderNode}
            renderMark={this.renderMark}
            onChange={this.onDocumentChange} />
        </div>
      </div>
    )
  }
            // Editor prop -->
            // ref={editor => this.editor = editor}

  renderNode = (props, next) => {
    switch (props.node.type) {
      case 'node_div':
        return <NodeDiv {...props} />
      case 'speaker_div':
        let speaker = props.node.data._root.entries[0][1]
        let speakerKey = props.node.data._root.entries[1][1]
        return (
          <SpeakerDiv 
            speakerKey={speakerKey} 
            speaker={speaker} {...props} />
        )
      case 'sentence_div':
        return <SentenceDiv {...props} />
      case 'word_span':
        let word = {
          string: props.node.data._root.entries[0][1],
          confidence: props.node.data._root.entries[3][1],
          start: props.node.data._root.entries[1][1],
          end: props.node.data._root.entries[2][1],
        }
        return (
          <WordSpan word={word} {...props} />
        )
      default:
        return next()
    }
  }

  renderMark = (props, next) => {
    switch (props.mark.type) {
      case 'bold':
        return <strong>{props.children}</strong>
      case 'italic':
        return <em>{props.children}</em>
      case 'underline':
        return <u>{props.children}</u>
      default:
        return next()
    }
  }
};

QuillEditor.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ 
    updateSpeakersArray,
    updateSentencesArray
  }, dispatch);
}
const mapStateToProps = ({ 
  editedtranscript,
  speakers,
  sentences,
  wavesurfer
}) => ({ 
  editedtranscript,
  speakers,
  sentences,
  wavesurfer
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(QuillEditor));
