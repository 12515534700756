import types from '../actions/types';

const INITIAL_STATE = {
  current_time: 0,
  playing: false,
  paused: false,
};

export default function WaveSurferReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_AUDIO_POSITION:
    case types.SKIP_TO_POSITION:
      let current_time = action.payload
      return { ...state, current_time }

    case types.PLAY_AUDIO:
      return { 
        ...state, 
        playing: action.payload,
        paused: false,
      }

    case types.PAUSE_AUDIO:
      return { 
        ...state, 
        playing: false,
        paused: action.payload,
      }

    default:
    //do nothing
  }
  return state;
}
