import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { editSpeakerName, updateSingleSpeaker, removeSpeaker } from '../actions/index';
import { openSnackbar } from './QuillSnackbar'

const PENCIL = require('../img/icon-pencil.svg');
const DELETE = require('../img/icon-delete.svg');

const styles = theme => ({})

class SpeakerName extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hovering: false,
      editable: false,
      value: this.props.speaker
    }
  }
  onMouseEnter = () => {
    this.setState((prevState) => ({ hovering: true }));
  }
  onMouseLeave = () => {
    this.setState((prevState) => ({ hovering: false, editable: false }));
  }
  editSpeaker = () => {
    this.setState(() => ({ hovering: false, editable: true }))
  }
  removeSpeaker = () => {
      openSnackbar({
        variant: 'error',
        message: `Deleted ${this.props.speaker}`,
      });
    this.setState(() => ({ hovering: false, editable: false }))
    this.props.removeSpeaker(this.props.speaker)
  }
  handleChange = (e) => {
    this.setState({ value: e.target.value })
  }
  handleSubmit = (e) => {
    if (this.props.speakers.includes(this.state.value)) {
      openSnackbar({
        variant: 'error',
        message: `Speaker already exists.`,
      });
      this.setState({ value: this.props.speaker })
      e.preventDefault();
      return 0
    } else if (this.state.value === '') {
      openSnackbar({
        variant: 'error',
        message: `Enter a valid speaker name.`,
      });
      this.setState({ value: this.props.speaker })
      e.preventDefault();
      return 0
    }
    this.props.editSpeakerName(this.props.speaker, this.state.value)
    this.props.handleSpeakerChange(this.state.value)
    e.preventDefault();
  }
  render() {
    return (
      <div 
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        key={this.props.speaker} 
        style={{ 
          display:'inline-flex', 
          minWidth: '120px', 
          float:'right', 
          padding: '1px 5px', 
          margin: '1px 5px' 
        }}>
        { this.state.editable ? 
            <form 
              style={{ display:'inline-flex', marginBottom:'5px'}}
              onSubmit={this.handleSubmit}>
              <input
                style={{ width: '85px' }} 
                type='text'
                value={this.state.value} 
                onChange={this.handleChange} />
              <input type='submit' value='Save' /> 
            </form>
            :
            <Typography 
              onClick={() => this.props.updateSingleSpeaker(this.props.speakerKey, this.props.speaker)}
              style={ this.state.hovering ? { display: 'inline-flex' } : {} }
              variant='body1'>{this.props.speaker}</Typography>
        }
        { (this.state.hovering && !this.state.clicked) && 
            <div style={{ 
              display:'inline-flex', 
              marginLeft: '10px', 
              paddingTop:'3px' 
            }}>
            <img 
              alt='pencil' 
              src={PENCIL} 
              style={{height:'12px', marginTop:'3px', paddingLeft: '3px'}} 
              onClick={() => this.editSpeaker()}/> 
            <img 
              alt='delete' 
              src={DELETE} 
              style={{height:'12px', marginTop:'3px', paddingLeft: '3px'}} 
              onClick={() => this.removeSpeaker()}/> 
          </div>
        }
      </div>
    )
  }
}

SpeakerName.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ 
    editSpeakerName, 
    updateSingleSpeaker, 
    removeSpeaker
  }, dispatch);
}
const mapStateToProps = ({ speakers }) => ({ speakers });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SpeakerName));
