import axios from 'axios';
import types from './types';
import { openSnackbar } from '../components/QuillSnackbar';
import Transcription from '../store/classes/Transcription';

export function uploadAndTranscribeAudio(audiofile) {
  return (dispatch, getState) => {
    return dispatch(uploadAudio(audiofile)).then(() => {
      const audio = getState().audio;
      if (!audio) {
        console.log('Audiofile not in props.');
        return;
      }
      return dispatch(getTranscription(audio));
    });
  };
}

export function skipToPosition(time) {
  return {
    type: types.SKIP_TO_POSITION,
    payload: time
  }
}

export function updateAudioPosition(time) {
  return {
    type: types.UPDATE_AUDIO_POSITION,
    payload: time
  }
}
export function playAudio() {
  return {
    type: types.PLAY_AUDIO,
    payload: true
  }
}

export function pauseAudio() {
  return {
    type: types.PAUSE_AUDIO,
    payload: true
  }
}

export function skipForward() {
  return dispatch => {
    dispatch({
      type: types.SKIP_FORWARD,
      payload: true
    })
    dispatch({
      type: types.PAUSE_AUDIO,
      payload: true
    })
  }
}

export function skipBackward() {
  return dispatch => {
    dispatch({
      type: types.SKIP_BACKWARD,
      payload: true
    })
    dispatch({
      type: types.PAUSE_AUDIO,
      payload: true
    })
  }
}

const uploadAudio = audiofile => {
  return dispatch => {
    dispatch({ type: types.UPLOAD_REQUEST });

    return uploadFile(audiofile).then(
      ({ data }) => {
        dispatch(uploadSuccess({ data }));
      },
      err => {
        dispatch(uploadFailure(err));
        throw err;
      },
    );
  };
};

export function uploadFile(audiofile) {
  let data = new FormData();
  data.append('audiofile', audiofile);

  const request = axios({
    method: 'post',
    url: 'https://api.quillit.io:8443/upload',
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });
  return request;
}

const getTranscription = audiofile => {
  return dispatch => {
    dispatch({ type: types.TRANSCRIPTION_REQUEST });
    return fetchTranscription(audiofile).then(
      ({ data }) => {
        let originaltranscript = new Transcription({ data })
        openSnackbar({
          variant: 'success',
          message: `Transcription successful.`,
        });
        dispatch({ type: types.TRANSCRIPTION_SUCCESS, originaltranscript });
      },
      err => {
        dispatch({ type: types.TRANSCRIPTION_FAILURE, err });
        throw err;
      },
    );
  };
};

export function uploadSuccess(audiofile) {
  return {
    type: types.UPLOAD_SUCCESS,
    payload: audiofile,
  };
}
export function uploadFailure(err) {
  openSnackbar({
    variant: 'error',
    message: `Error: ${err}`,
  });
  return {
    type: types.UPLOAD_FAILURE,
    err,
  };
}

export function fetchTranscription(audiofile) {
  const filename = audiofile.originalname;
  const request = axios({
    method: 'get',
    url: 'https://api.quillit.io:8443/transcribe',
    params: { filename },
  });
  return request;
}

export function updateTranscriptionMarkup(markup) {
  return {
    type: types.UPDATE_TRANSCRIPTION_MARKUP,
    payload: markup,
  };
}

export function updateSentencesArray(sentences) {
  return {
    type: types.UPDATE_SENTENCES,
    payload: sentences
  };
}

export function updateSpeakersArray(speakers) {
  return {
    type: types.UPDATE_SPEAKERS,
    payload: speakers
  };
}

export function addSpeaker(speaker) {
  return {
    type: types.ADD_SPEAKER,
    payload: speaker
  };
};

export function editSpeakerName(original, edited) {
  return (dispatch, getState) => {
    let speakers = getState().speakers;
    let speakerIndex = speakers.indexOf(original)

    return dispatch({
      type: types.EDIT_SPEAKER, 
      payload: {
        index: speakerIndex,
        edited
      }
    })
  }
}

export function removeSpeaker(speaker) {
  return (dispatch, getState) => {
    let speakers = getState().speakers;
    let index = speakers.indexOf(speaker)

    return dispatch({
      type: types.REMOVE_SPEAKER, 
      payload: index
    })
  }
}

export function updateSingleSpeaker(key, updated) {
  return (dispatch, getState) => {
    let original = getState().speakers
    original.splice(key, 1, updated)

    dispatch(updateSpeakersArray(original))

    dispatch({
      type: types.UPDATE_SINGLE_SPEAKER,
      payload: {
        key,
        updated
      }
    });
  };
};

export function updateSpeakers(original, updated) {
  return (dispatch, getState) => {

    let sentences = getState().editedtranscript
    let indices = [], i
    for ( i=0; i < sentences.length; i++) {
      if (sentences[i].speaker === original) {
        indices.push(i)
      }
    }

    return dispatch({
      type: types.UPDATE_SENTENCE_SPEAKERS,
      payload: {
        indices,
        updated
      }
    });
  }
};

export function toggleLoading() {
  return {
    type: types.TOGGLE_LOADING,
  };
}
