import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  audioOutputDiv: {
    height: '8vh',
    textAlign: 'center',
    padding: '10px 0px',
  },
  exportIcon: {
    height: '40px',
  },
  exportBtn: {
    height: '60px',
    width: '60px',
    margin: '0px 20px -50px'
  },
});


class ExportOptions2 extends React.Component {

  constructor(props) {
    super(props);
    this.downloadPlaintext = this.downloadPlaintext.bind(this);
  }
  downloadPlaintext() {
    var fileDownload = require('js-file-download');
    var tt = this.props.editedtranscript.map(({speaker}, i) => {
      //var sentence = words.map(wordObj => wordObj.word).join(' ');
      return `${speaker}: ${this.props.sentences[i]}`
    })
    var transcript = tt.join('\n')
    fileDownload(transcript, 'transcription.txt');
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.audioOutputDiv}>
        <Button className={classes.exportBtn} onClick={() => {this.downloadPlaintext()}}>
          <img className={classes.exportIcon} src={require('../img/document-icon.svg')} alt="plain-text" />
        </Button>
        <Button className={classes.exportBtn} onClick={() => {console.log('download pdf transcription')}}>
          <img className={classes.exportIcon} src={require('../img/pdf-icon.svg')} alt="pdf" />
        </Button>
        <Button className={classes.exportBtn} onClick={() => {console.log('email transcription')}}>
          <img className={classes.exportIcon} src={require('../img/envelope-icon.svg')} alt="email" />
        </Button>
      </div>
    )
  }
};

ExportOptions2.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ 
  editedtranscript, 
  sentences 
}) => ({ 
  editedtranscript, 
  sentences 
});

export default connect(mapStateToProps)(withStyles(styles)(ExportOptions2));
