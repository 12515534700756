const UPLOAD_REQUEST = 'UPLOAD_REQUEST';
const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
const UPLOAD_FAILURE = 'UPLOAD_FAILURE';

const TRANSCRIPTION_REQUEST = 'TRANSCRIPTION_REQUEST';
const TRANSCRIPTION_SUCCESS = 'TRANSCRIPTION_SUCCESS';
const TRANSCRIPTION_FAILURE = 'TRANSCRIPTION_FAILURE';

const UPDATE_PAGE_INDEX = 'UPDATE_PAGE_INDEX';
const GO_TO_PREVIOUS_PAGE = 'GO_TO_PREVIOUS_PAGE';
const GO_TO_NEXT_PAGE = 'GO_TO_NEXT_PAGE';
const TOGGLE_LOADING = 'TOGGLE_LOADING';

const UPDATE_SENTENCES = 'UPDATE_SENTENCES';

const UPDATE_SPEAKERS = 'UPDATE_SPEAKERS';
const ADD_SPEAKER = 'ADD_SPEAKER';
const EDIT_SPEAKER = 'EDIT_SPEAKER';
const REMOVE_SPEAKER = 'REMOVE_SPEAKER';

const UPDATE_SINGLE_SPEAKER = 'UPDATE_SINGLE_SPEAKER';
const UPDATE_SENTENCE_SPEAKERS = 'UPDATE_SENTENCE_SPEAKERS';

const UPDATE_AUDIO_POSITION = 'UPDATE_AUDIO_POSITION'
const SKIP_TO_POSITION = 'SKIP_TO_POSITION'
const PLAY_AUDIO = 'PLAY_AUDIO'
const PAUSE_AUDIO = 'PAUSE_AUDIO'
const SKIP_BACKWARD = 'SKIP_BACKWARD'
const SKIP_FORWARD = 'SKIP_FORWARD'
  
export default {
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS,
  UPLOAD_FAILURE,
  TRANSCRIPTION_REQUEST,
  TRANSCRIPTION_SUCCESS,
  TRANSCRIPTION_FAILURE,
  UPDATE_PAGE_INDEX,
  GO_TO_PREVIOUS_PAGE,
  GO_TO_NEXT_PAGE,
  TOGGLE_LOADING,
  UPDATE_SENTENCES,
  UPDATE_SPEAKERS,
  ADD_SPEAKER,
  EDIT_SPEAKER,
  REMOVE_SPEAKER,
  UPDATE_SINGLE_SPEAKER,
  UPDATE_SENTENCE_SPEAKERS,
  UPDATE_AUDIO_POSITION,
  SKIP_TO_POSITION,
  PLAY_AUDIO,
  PAUSE_AUDIO,
  SKIP_BACKWARD,
  SKIP_FORWARD
};
