import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';

import FileUploadGraphic from './FileUploadGraphic';

const styles = theme => ({
  dropzone: {
    width: '100%',
    overflow: 'hidden',
    border: 'none',
    display: 'table-cell',
    verticalAlign: 'middle'
  },
});

class FileUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dragging: false
    }
  }
  handleDragEnter() {
    this.setState({dragging: true}, () => {
    })
  }
  handleDragLeave() {
    this.setState({dragging: false})
  }
  render() {
    const { classes } = this.props;

    return (
      <Dropzone 
        className={classes.dropzone}
        accept="audio/*" 
        disableClick={true}
        onDragEnter={() => { this.handleDragEnter() }}
        onDragLeave={() => { this.handleDragLeave() }}
        onDrop={(accepted) => { this.props.handleFileSelection(accepted[0]) }}>
        <FileUploadGraphic 
          handleFileSelection={this.props.handleFileSelection}
          dragging={this.state.dragging} />
      </Dropzone>
    )
  }
};

FileUpload.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FileUpload);
