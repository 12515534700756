import types from '../actions/types';

const INITIAL_STATE = null;

export default function AudioReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPLOAD_SUCCESS:
      let file = action.payload.data;
      if (!file) { return 'dev' }
      return file

    default:
    //do nothing
  }
  return state;
}
