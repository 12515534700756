import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { addSpeaker } from '../actions/index';
import { openSnackbar } from './QuillSnackbar'

const ADD = require('../img/icon-add.svg');

const styles = theme => ({})

class NewSpeaker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hovering: false, 
      clicked: false, 
      value: '' 
    }
  }
  handleClick = () => {
    this.setState(() => ({ clicked: true }))
  }
  onMouseEnter = () => {
    this.setState(() => ({ hovering: true }))
  }
  onMouseLeave = () => {
    this.setState(() => ({ hovering: false, clicked: false }))
  }
  handleChange = (e) => {
    this.setState({ value: e.target.value })
  }
  handleSubmit = (e) => {
    if (this.props.speakers.includes(this.state.value)) {
      openSnackbar({
        variant: 'error',
        message: `Speaker already exists.`,
      });
      this.setState({ value: '' })
      e.preventDefault();
      return 0
    } else if (this.state.value === '') {
      openSnackbar({
        variant: 'error',
        message: `Enter a valid speaker name.`,
      });
      e.preventDefault();
      return 0
    }
    this.props.addSpeaker(this.state.value)
    e.preventDefault();
  }
  render() {
    return (
      <div 
        style={{ 
          minWidth: '120px', 
          display: 'inline-flex', 
          zIndex: '1', 
          float: 'right', 
          padding: '1px 5px', 
          margin: '1px 5px',
          marginLeft: '-25px' }}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={this.handleClick}>
        { this.state.clicked ? 
          <div>
            <form 
              style={{ 
              display:'inline-flex', 
              marginBottom:'5px' }} 
              onSubmit={this.handleSubmit}>
              <input 
                style={{ width: '85px' }} 
                type="text" 
                value={this.state.value} 
                onChange={this.handleChange} />
              <input 
                type="submit" 
                value="Add" />
            </form>
          </div> :
          <Typography variant='body1'>New Speaker</Typography> }
          {
            (this.state.hovering && !this.state.clicked) && 
              <img
                alt='add'
                src={ADD}
                style={{ 
                  height:'14px', 
                  paddingLeft:'6px', 
                  marginTop:'6px' }} />
          }
        </div>
    )
  }
}

NewSpeaker.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ addSpeaker }, dispatch);
}
const mapStateToProps = ({ 
  editor,
  speakers
}) => ({ 
  editor,
  speakers
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewSpeaker));
