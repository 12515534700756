import Sentence from './Sentence'
import Word from './Word'

export default class Transcription {
  constructor({data}) {
    this.sentences = []
    this.original = data.transcript
    this.confidence = data.confidence
    this.createSentences(data.words)
  }
  createSentences(words) {
    let sentence = new Sentence();
    //sentence.speaker = words[0].speakerTag;
    sentence.speaker = `(speaker ${words[0].speakerTag})`
    words.map((wordInfo, i) => {
      let word = new Word(wordInfo);
      if (`(speaker ${words[i].speakerTag})` === sentence.speaker) {
        sentence.words.push(word)
      } else {
        this.sentences.push(sentence)
        sentence = new Sentence()
        sentence.words.push(word)
        //sentence.speaker = words[i].speakerTag
        sentence.speaker = `(speaker ${words[i].speakerTag})`
      }
      if (i === words.length - 1) {
        this.sentences.push(sentence)
      }
      return 1
    })
  }
}
