import types from '../actions/types';

const INITIAL_STATE = [];

export default function SpeakersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_SPEAKERS:
      return action.payload

    case types.ADD_SPEAKER:
      return [...state, action.payload]

    case types.EDIT_SPEAKER:
      let newState = [...state]
      newState[action.payload.index] = action.payload.edited
      return newState
      
    case types.REMOVE_SPEAKER:
      let removedState = [
        ...state.slice(0, action.payload),
        ...state.slice(action.payload + 1)
      ]
      return removedState
      
    default:
    //do nothing
  }
  return state;
}
