import types from '../actions/types';

export default function LoadingReducer(state = false, action) {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      return !state;

    case types.TRANSCRIPTION_REQUEST:
    case types.UPLOAD_REQUEST:
      return true;

    case types.TRANSCRIPTION_SUCCESS:
    case types.TRANSCRIPTION_FAILURE:
    case types.UPLOAD_SUCCESS:
    case types.UPLOAD_FAILURE:
      return false;

    default:
    //do nothing
  }
  return state;
}
