// Output.jsx
import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import AudioOutput from '../components/AudioOutput'
import OutputToolbar from '../components/OutputToolbar'
import QuillEditor from '../components/QuillEditor'

const styles = theme => ({
  outputDiv: {
    width: '100%',
    overflow: 'scroll',
  },
  btnExport: {
    marginRight: '50px',
    float: 'right',
    fontWeight: 'bold',
    fontSize: '14px'
  },
  editText: {
    padding: '25px 75px',
  },
});

class Output extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.outputDiv}>
        <AudioOutput audiofile={this.props.audiofile}/>
        <OutputToolbar audiofile={this.props.audiofile}/>
        { this.props.originaltranscript &&
            <QuillEditor originaltranscript={this.props.originaltranscript}/>
        }
      </div>
    )
  }
};

Output.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Output);
